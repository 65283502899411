html {
    overflow-y: scroll;
    scroll-behavior: smooth;
}

body {
    /* background: hsl(207, 100%, 98%); */
    background: hsl(0, 5%, 13%);
    color: hsl(207, 100%, 98%);
    animation: load 1.0s ease-in;
}

@keyframes load {
    from {
      opacity: 0;
    }
    to {
      opacity: 1;
      }
}

::selection {
    color: hsl(0, 5%, 13%);
    background: hsl(165, 100%, 80%);
}

nav {
  position: fixed;
  display: flex;
  width: 0;
  height: 100%;
  top: 0;
  left: 0;
  right: 0;
  z-index: 1;
  background: hsl(0, 5%, 13%);
  box-shadow: 0px 0px 0px 0px hsl(0, 5%, 13%);
  transition: all 0.3s ease-in-out;
}

nav.active {
  width: 6em;
  box-shadow: 0px 0px 20px 40px hsl(0, 5%, 13%);
}

/* header .dropped {
    position: fixed;
    display: flex;
    width: 0vw;
    height: 100vh;
    top: 0;
    left: 0;
    right: 0;
    background: hsl(207, 100%, 100%);
    color: hsl(207, 100%, 98%);
    transition: all 0.4s ease-in-out;
} */
  
/* header {
    position: fixed;
    display: flex;
    height: 10vh;
    top: 0;
    left: 0;
    right: 0;
    text-align: center;
    justify-content: center;
    background: hsl(0, 0%, 20%);
    box-shadow: 0px 0px 4px 2px hsl(0, 0%, 20%);
    color: hsl(207, 100%, 98%);
    transition: all 0.4s ease-in-out;
} */

/* header .called:hover {
    box-shadow: 0px 0px 4px 2px hsl(0, 0%, 20%);
} */

a:link {
    color: hsl(207, 100%, 98%);
    text-decoration: none;
}
  
  /* visited link */
a:visited {
    color: hsl(0, 0%, 60%);
}
  
  /* mouse over link */
a:hover {
    color: hsl(200, 80%, 40%);
}
  
  /* selected link */
a:active {
    color: hsl(210, 100%, 50%);
}

#home,
#work,
#resume,
#connect {
    /* display: inline-block; */
    position: relative;
    display: block;
    color: hsl(207, 100%, 98%);
    margin-left: 1.2em;
    top: 4vh;
    opacity: 0;
    margin-block: 7vh;
    font-family: 'Patua One', cursive;
    font-size: 1.2em;
    transition: all 0.2s ease-in-out;
    animation: reveal 0.4s ease forwards;
    filter: drop-shadow(0px 0px 1px hsl(0, 5%, 13%));
}

@keyframes reveal {
	from {
		transform: translateX(-100%);
	}
	to {
		transform: translateX(0);
		opacity: 1;
	}
}

#home:hover,
#work:hover,
#resume:hover,
#connect:hover {
    transform: translate(0%, 4%);
    font-weight: bolder;
    scale: 1.1;
    color: hsl(207, 100%, 98%);
    filter: drop-shadow(0px 1px 1px hsl(0, 5%, 13%));
}

#menu {
  position: relative;
  color: hsl(167, 100%, 40%);
  /* margin: 1em; */
  /* margin-left: 3vw; */
  scale: 1.5;
  top: 3vh;
  left: 1em;
  z-index: 1;
  cursor: pointer;
  transition: all 0.2s ease-in-out;
}

#menu:active {
  color: hsl(0, 5%, 13%);
}

#menu:hover {
  color: hsl(207, 100%, 98%);
  filter: drop-shadow(0px 0px 1px hsl(0, 5%, 13%));
  font-weight: bolder;
}

footer {
    position: fixed;
    opacity: 0.6;
    bottom: 0;
    left: 0;
    right: 0;
    width: 100%;
    height: 0px;
    border-bottom: hsl(167, 100%, 40%) 12px solid;
    text-align: left;
    color: hsl(210, 100%, 98%);
    font-family: 'JetBrains Mono', monospace;
    font-size: 14px;
    transition: all 0.2s ease-in-out;
  }
  
  footer:hover {
    border-bottom: hsl(0, 0%, 90%) 12px solid;
    opacity: 1;
    height: 40px;
  }
  
  .end-note {
    font-weight: bolder;
    font-size: larger;
    margin-left: 75%;
  }

  #palm {
    position: absolute;
    display: block;
    max-height: 140%;
    height: auto;
    top: -20%;
    margin-left: 86%;
}