#profile {
  position: absolute;
  opacity: 0.6;
  top: 20vh;
  right: 6vh;
  z-index: -1;
  width: 24vh;
  border-radius: 12px;
  box-shadow: 0px 0px 40px 4px hsl(0, 0%, 60%);
}

#github {
  position: relative;
  display: block;
  margin-top: 30vh;
  left: 4vh;
  width: 6vh;
  height: auto;
  filter: invert(1);
  border-radius: 100%;
  border: hsl(0, 0%, 60%) 2px solid;
  filter: drop-shadow(0px 0px 1px hsl(0, 0%, 60%));
  transition: all 0.2s ease-in-out;
  animation: reveal 0.4s ease forwards;
}

@keyframes reveal-github {
  from {
    transform: translateX(300%);
    opacity: 0;
  }

  to {
    transform: translateX(0);
    opacity: 1;
    filter: invert(1);
  }
}

#github:hover {
  width: 6.4vh;
  left: 3.8vh;
  filter: invert(1);
  border: transparent 2px solid;
}

h1 {
  display: block;
  cursor: default;
  width: 100%;
  color: hsl(207, 100%, 98%);
  border-bottom: 6px ridge hsl(207, 100%, 100%);
  height: auto;
  text-align: center;
  font-family: 'Pacifico', cursive;
  font-size: 16vh;
  text-shadow: -4px 6px #C9CEBD;
}

/* h1::before {  
  transform: scaleX(0);
  transform-origin: top right;
}

h1:hover::before {
  transform: scaleX(1);
  transform-origin: top left;
}

h1::before {
  content: " ";
  display: inline-block;
  position: absolute;
  top: 0; right: 0; bottom: 0; left: 0;
  background: hsl(207, 100%, 100%);
  box-shadow: 0px 0px 4px 2px hsl(0, 0%, 90%);
  z-index: -1;
  transition: transform 0.4s ease-in-out;
} */

#anchorHome:hover {
  color: hsl(210, 100%, 50%);
}

#anchorHome:visited {
  color: none;
}

h2 {
  /* position: fixed;
  display: block; */
  margin-left: 20vh;
  margin-top: -10vh;
  margin-bottom: 40vh;
  text-align: center;
  font-family: 'Patua One', cursive;
  font-size: 1.6em;
  text-shadow: -2px 1px #C9CEBD;
  z-index: -1;
  cursor: default;
  color: hsl(207, 100%, 98%);
}

h3 {
  display: block;
  padding-top: 4%;
  text-align: center;
  font-family: 'Patua One', cursive;
  font-size: 32px;
  text-shadow: -1px 1px hsl(0, 0%, 60%);
  cursor: default;
}

h4 {
  opacity: 1;
  display: block;
  text-align: center;
  font-family: 'Patua One', cursive;
  font-size: medium;
  text-shadow: -1px 1px hsl(0, 0%, 60%);
  cursor: default;
  width: 50%;
}

.next {
  margin-top: 10vh;
  height: 120vh;
}

.isVisible {
  position: absolute;
  width: auto;
  font-size: larger;
  color: hsl(207, 100%, 98%);
  animation: slideUp 1.4s ease forwards;
}

@keyframes slideUp {
  from {
    transform: translateY(-20%);
    opacity: 0;
  }

  to {
    transform: translateX(0);
    opacity: 1;
  }
}

.topics {
  font-family: 'JetBrains Mono', monospace;
  font-size: 14px;
  margin-left: 10%;
  padding-top: 4%;
  line-height: 24px;
  width: 80%;
  cursor: default;
  transition: all 0.3 ease-in;
  animation: fadeIn 1s ease-in;
}

.first-p {
  text-indent: 20px;
  margin-right: 30%;
  margin-left: 5%;
  margin-bottom: 3%;
}

.second-p {
  text-indent: 20px;
  margin-right: 5%;
  margin-left: 30%;
  margin-bottom: 3%;
}

.third-p {
  text-indent: 20px;
  margin-top: -6%;
  margin-right: 5%;
  margin-left: 5%;
}

.resume-bullets {
  font-family: 'JetBrains Mono', monospace;
  margin-right: 8%;
  margin-left: 12%;
  margin-bottom: 5%;
}

.resume-bullets li {
  list-style: square;
  margin: 1vh;
}

mark {
  color: hsl(207, 100%, 100%);
  background: hsl(0, 0%, 90%);
  padding-left: 4px;
  padding-right: 4px;
  border-radius: 2px;
}

.bold {
  color: hsl(167, 100%, 40%);
  font-size: medium;
}

b {
  font-size: medium;
}

.about-me {
  margin-right: 30%;
  margin-left: 10%;
}

.study {
  margin-top: 8%;
  margin-left: 34%;
  margin-right: 10%;
}

.ext-link:hover {
  text-decoration: underline;
  color: hsl(210, 100%, 50%);
}

.ext-link:active {
  color: black;
}

.container {
  padding: 20px 5%;
  margin-left: auto;
  margin-right: auto;
  margin-left: -50px;
}

.home {
  padding: 20px 5%;
  margin-left: auto;
  margin-right: auto;
  margin-bottom: 300px;
}

.featured-div {
  position: relative;
  display: inline;
  margin-left: 36%;
}

#holiday {
  animation: holiday 10s both infinite;
}

@keyframes holiday {
  0% {
    filter: drop-shadow(0px 0px 40px #FF6666);
    scale: 1.0;
  }

  50% {
    filter: drop-shadow(0px 0px 60px #e6e6e6);
    scale: 1.2;
  }

  100% {
    filter: drop-shadow(0px 0px 40px #FF6666);
    scale: 1.0;
  }
}

.featured-div .title {
  text-shadow: -1px 1px hsl(0, 0%, 60%);
}

.featured-div p {
  position: relative;
  text-indent: 0px;
  right: -20vw;
  width: 60%;
  margin-right: 5%;
  margin-left: -2%;
  margin-bottom: 3%;
}

#observer-div {
  margin-top: 20vh;
  margin-bottom: 40vh;
}

ul {
  list-style: none;
  transition: all 0.3 ease-in;
  animation: fadeIn 1s ease-in;
}

.image-gallery {
  display: flex;
  flex-wrap: wrap;
  justify-content: center;
  gap: 60px;
}

.image-gallery>li {
  flex-basis: 300px;
  /*width: 300px;*/
  cursor: default;
}

.image-gallery li:hover .overlay {
  box-shadow: 0px 0px 10px 2px hsl(0, 0%, 90%);
}

.image-gallery::after {
  content: "";
  flex-basis: 300px;
}

.image-gallery li img {
  /* box-shadow: 0px 0px 4px 2px hsl(0, 0%, 90%); */
  object-fit: cover;
  max-width: 100%;
  height: auto;
  vertical-align: middle;
}

.overlay {
  width: 100%;
  height: auto;
  padding: 4%;
  background: transparent;
  border-radius: 4%;
  transition: all 0.3s ease-in-out;
}

.title {
  font-family: 'Patua One', cursive;
  text-shadow: -1px 1px hsl(0, 0%, 60%);
  font-weight: bolder;
  align-items: center;
  text-align: center;
  justify-content: center;
}

.description {
  font-family: 'JetBrains Mono', monospace;
  font-size: 14px;
  line-height: 24px;
  text-align: center;
}

.icon-links {
  display: flex;
  align-items: center;
  justify-content: center;
}

.open-in-browser,
.terminal {
  color: hsl(207, 100%, 98%);
  transition: all 0.2s ease-in-out;
}

.open-in-browser:hover,
.terminal:hover {
  scale: 1.2;
  cursor: pointer;
  color: hsl(210, 100%, 50%);
}

.open-in-browser:active,
.terminal:active {
  color: hsl(210, 100%, 50%);
}

.icon {
  position: relative;
  cursor: pointer;
  font-family: 'Patua One', cursive;
  text-align: center;
  font-size: 12px;
  margin-left: 10%;
  margin-right: 10%;
}

.resumePage {
  margin: 2em;
  transition: all 0.3 ease-in;
  animation: fadeIn 1s ease-in;
  /* box-shadow: 0px 0px 4px 2px hsl(0, 0%, 90%); */
}

.resume-p {
  font-family: 'JetBrains Mono', monospace;
  font-size: 14px;
  text-indent: 20px;
  margin-right: 15%;
  margin-left: 15%;
}

@keyframes fadeIn {
  from {
    opacity: 0;
  }

  to {
    opacity: 1;
  }
}

.points {
  margin: auto;
  width: 80%;
  padding-bottom: 10px;
  /* margin-bottom: 10px; */
  border-bottom: 2px solid hsl(0, 0%, 90%);
}

#contact-me input[type="button"] {
  position: relative;
  display: block;
  margin: 6vh;
  left: 1vh;
}

input[type="text"],
textarea {
  font-family: 'JetBrains Mono', monospace;
  position: relative;
  display: block;
  color: hsl(0, 0%, 90%);
  margin: 4%;
  padding-bottom: 4px;
  background-color: transparent;
  border: transparent;
  border-bottom: hsl(0, 0%, 60%) 2px solid;
  width: 36vh;
  overflow: hidden; 
  resize: none;
  transition: all 0.2s ease-in-out;
}

input[type="text"]:focus {
  border-bottom: hsl(167, 100%, 40%) 2px solid;
  outline: none;
  scale: 1.05;
}

textarea:focus {
  text-align: left;
  border-bottom: hsl(167, 100%, 40%) 2px solid;
  outline: none;
  scale: 1.05;
}

input[type="button"] {
  box-shadow: 0 0 0 2em rgba(#fff,0);
  font-family: 'JetBrains Mono', monospace;
  position: relative;
  color: hsl(0, 0%, 60%);
  font-weight: bold;
  font-size: larger;
  padding: 4px;
  border-radius: 10px;
  border: hsl(0, 0%, 60%) 2px solid;
  background-color: hsl(0, 5%, 13%);
  width: 6em;
  transition: border 0.1s ease-in-out, border-radius 0.2s ease-in-out, scale 0.3s ease-in-out;
}

@keyframes hue {
  from {
    filter: hue-rotate(0deg);
  }

  to {
    filter: hue-rotate(360deg);
  }
}

input[type="button"]:hover {
  cursor: pointer;
  color: hsl(0, 0%, 90%);
  border: hsl(167, 100%, 40%) 2px solid;
  border-radius: 8px;
  scale: 1.1;
  animation: end;
  animation: hue 4s both infinite;
}

input[type="button"]:active {
  color: hsl(210, 100%, 50%);
  animation: end;
}